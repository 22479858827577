




















import { Vue, Component, Prop } from "vue-property-decorator";
import { RouteName } from "@/router/types";
import MenuItem from "@/components/MenuItem.vue";
import { routes } from "@/router/routes";
import { routeEnabled } from "@/router";
import { RouteConfig } from "vue-router";

@Component({
  components: {
    MenuItem,
  },
})
export default class OverviewList extends Vue {
  @Prop({ required: true }) routeName!: RouteName;

  get navItems() {
    const item = routes.find(
      (route) =>
        route.name === this.routeName ||
        (route.children &&
          route.children.find(
            (childRoute) => childRoute.name === this.routeName
          ))
    );
    const navItems = [
      ...(item ? (item?.children ? item.children : [item]) : []),
    ];

    return navItems.filter(
      (navItem) =>
        navItem.name !== this.routeName && routeEnabled(navItem, item)
    );
  }

  itemTitle(item: RouteConfig): string {
    return item.meta?.overviewPanel?.title().toString() || "";
  }
  itemDescription(item: RouteConfig): string {
    return item.meta?.overviewPanel?.description().toString() || "";
  }
  itemGroupHeading(item: RouteConfig): string {
    const heading = item?.meta?.overviewPanel?.group().toString() || "";
    return heading;
  }
  headingChanged(item: RouteConfig, previousItem: RouteConfig): boolean {
    return this.itemGroupHeading(item) !== this.itemGroupHeading(previousItem);
  }
}
