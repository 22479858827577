



























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MenuItem extends Vue {
  @Prop() readonly path!: string;
  @Prop() readonly title!: string;
  @Prop() readonly subtitle!: string;
}
